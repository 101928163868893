<template>
  <div style="min-height: 750px;">
    <b-row>
      <b-col sm="12" lg="2"></b-col>
      <b-col sm="12" lg="8"></b-col>
      <b-col sm="12" lg="2"></b-col>
    </b-row>
    <b-row style="margin-bottom: 20px; border-bottom: solid 1px whitesmoke;">
      <b-col sm="12" md="2" lg="2"> </b-col>
      <b-col sm="12" md="4" lg="4">
        <i class="fa fa-desktop"></i>
        {{ StoreLangTranslation.data['shop'][StoreLang] }}
      </b-col>
      <b-col sm="12" md="4" lg="4" :style="StoreDevice.isMobile ? '' : 'text-align: right;'"></b-col>
      <b-col sm="12" md="2" lg="2"></b-col>
    </b-row>
    <div class="wrapper">
      <div class="animated fadeIn">
          <template v-if="d_loading">
            <b-spinner variant="primary" label="Spinning"></b-spinner>
          </template>
          <template v-else>
            <template v-for="(shop_id, shop_index) in d_productList.param_order">
              <b-row style="margin-bottom: 10px; font-size: 14px;">
                  <b-col sm="12" lg="2"></b-col>
                  <b-col sm="12" lg="8">
                    <b-row style="margin: 0px; background-color: #93ffe4; padding: 5px;">
                      <b-col sm="12" lg="4">
                          <template v-if="d_productList.parameters[shop_id].data.data && d_productList.parameters[shop_id].data.data['0'] && d_productList.parameters[shop_id].data.data['0']['2'] && d_productList.parameters[shop_id].data.data['0']['2']['lang'] && d_productList.parameters[shop_id].data.data['0']['2']['lang'][StoreLang]">
                            <img :src="d_productList.parameters[shop_id].data.data['0']['2']['lang'][StoreLang]" style="width: 30px; margin-right: 5px;">
                          </template>
                          <template v-else-if="d_productList.parameters[shop_id].data.data && d_productList.parameters[shop_id].data.data['0'] && d_productList.parameters[shop_id].data.data['0']['2'] && d_productList.parameters[shop_id].data.data['0']['2']['val']">
                            <img :src="d_productList.parameters[shop_id].data.data['0']['2']['val']" style="width: 30px; margin-right: 5px;">
                          </template>

                          <template v-if="d_productList.parameters[shop_id].data.data && d_productList.parameters[shop_id].data.data['0'] && d_productList.parameters[shop_id].data.data['0']['1'] && d_productList.parameters[shop_id].data.data['0']['1']['lang'] && d_productList.parameters[shop_id].data.data['0']['1']['lang'][StoreLang]">
                            <strong :title="d_productList.parameters[shop_id].data.data['0']['1']['lang'][StoreLang]">{{ d_productList.parameters[shop_id].data.data['0']['1']['lang'][StoreLang].length > 30 ? d_productList.parameters[shop_id].data.data['0']['1']['lang'][StoreLang].substring(0,30) + '...' : d_productList.parameters[shop_id].data.data['0']['1']['lang'][StoreLang] }}</strong>
                          </template>
                          <template v-else-if="d_productList.parameters[shop_id].data.data && d_productList.parameters[shop_id].data.data['0'] && d_productList.parameters[shop_id].data.data['0']['1'] && d_productList.parameters[shop_id].data.data['0']['1']['val']">
                            <strong :title="d_productList.parameters[shop_id].data.data['0']['1']['val']">{{ d_productList.parameters[shop_id].data.data['0']['1']['val'].length > 30 ? d_productList.parameters[shop_id].data.data['0']['1']['val'].substring(0,30) + '...' : d_productList.parameters[shop_id].data.data['0']['1']['val'] }}</strong>
                          </template>
                      </b-col>
                      <b-col sm="12" lg="3">
                        <i class="fa fa-envelope" style="margin-right: 5px;"></i>
                          <template v-if="d_productList.parameters[shop_id].data.data && d_productList.parameters[shop_id].data.data['0'] && d_productList.parameters[shop_id].data.data['0']['29'] && d_productList.parameters[shop_id].data.data['0']['29']['lang'] && d_productList.parameters[shop_id].data.data['0']['29']['lang'][StoreLang]">
                            <strong>{{ d_productList.parameters[shop_id].data.data['0']['29']['lang'][StoreLang] }}</strong>
                          </template>
                          <template v-else-if="d_productList.parameters[shop_id].data.data && d_productList.parameters[shop_id].data.data['0'] && d_productList.parameters[shop_id].data.data['0']['29'] && d_productList.parameters[shop_id].data.data['0']['29']['val']">
                            <strong>{{ d_productList.parameters[shop_id].data.data['0']['29']['val'] }}</strong>
                          </template>
                      </b-col>
                      <b-col sm="12" lg="3">
                        <i class="fa fa-mobile-phone" style="margin-right: 5px;"></i>
                          <template v-if="d_productList.parameters[shop_id].data.data && d_productList.parameters[shop_id].data.data['0'] && d_productList.parameters[shop_id].data.data['0']['30'] && d_productList.parameters[shop_id].data.data['0']['30']['lang'] && d_productList.parameters[shop_id].data.data['0']['30']['lang'][StoreLang]">
                            <strong>{{ d_productList.parameters[shop_id].data.data['0']['30']['lang'][StoreLang] }}</strong>
                          </template>
                          <template v-else-if="d_productList.parameters[shop_id].data.data && d_productList.parameters[shop_id].data.data['0'] && d_productList.parameters[shop_id].data.data['0']['30'] && d_productList.parameters[shop_id].data.data['0']['30']['val']">
                            <strong>{{ d_productList.parameters[shop_id].data.data['0']['30']['val'] }}</strong>
                          </template>
                      </b-col>
                      <b-col sm="12" lg="1"></b-col>
                    </b-row>
                  </b-col>
                  <b-col sm="12" lg="2"></b-col>
              </b-row>
              <template v-for="(bundle_group_id, bundle_group_index) in d_productList.parameters[shop_id].groups.param_order">
                <b-row style="font-size: 14px;">
                    <b-col sm="12" lg="2"></b-col>
                    <b-col sm="12" lg="8">
                      <b-row style="text-align: left; font-weight: 900; border-radius: 10px 10px; background-color: #adfcbd; margin: 3px; padding: 10px;">
                        <b-col cols="12">
                          <i class="fa fa-angle-double-right"></i>
                          <strong> {{ d_productList.parameters[shop_id].groups.parameters[bundle_group_id]['name'][StoreLang].toLocaleUpperCase() }}</strong>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col sm="12" lg="2"></b-col>
                </b-row>
                <b-row style="font-size: 14px;">
                    <b-col sm="12" lg="2"></b-col>
                    <b-col sm="12" lg="8">
                      <b-row style="margin-bottom: 20px;">
                        <template v-for="(bundle_id, bundle_id_index) in d_productList.parameters[shop_id].groups.parameters[bundle_group_id].id_list">
                          <b-col v-if="f_viewBundle(shop_id, bundle_id)" sm="12" lg="4" style="padding: 10px;">
                            <b-card class="product-card" @click="f_goToBundleDetails(d_productList.parameters[shop_id].data.client_id, d_productList.parameters[shop_id].parameters[bundle_id].data.client_id)">
                              <!-- background-image: linear-gradient(90deg, #f7fcfd, #e8f6f9, #d5efed, #b7e4da, #8fd4c1, #69c2a3, #49b17f, #2f995a, #157f3c, #036429, #036429); -->
                              <b-row style="margin: 3px; min-height: 75px; text-align: center; background-color: #f7e48f; padding: 10px; border-radius: 10px; box-shadow: -2px -2px #d4a803;">
                                <b-col sm="12" md="12">
                                  <strong> {{ d_productList.parameters[shop_id].parameters[bundle_id].data.data['0']['1']['lang'][StoreLang] }} </strong>
                                </b-col>
                              </b-row>
                              <b-row v-if="d_productList.parameters[shop_id].parameters[bundle_id].data && d_productList.parameters[shop_id].parameters[bundle_id].data.data && d_productList.parameters[shop_id].parameters[bundle_id].data.data['0'] && d_productList.parameters[shop_id].parameters[bundle_id].data.data['0']['12'] && d_productList.parameters[shop_id].parameters[bundle_id].data.data['0']['12']['val']['value'] === '1'" style="margin: 3px; min-height: 20px; text-align: center; font-size: 16px;">
                                <b-col sm="12" md="12" lg="1">
                                </b-col>
                                <b-col sm="12" md="12" lg="10">
                                  <div style="background-color: #feffe7; border-radius: 0px 30px 0px 30px; border: solid 1px #858585;">
                                    {{ StoreLangTranslation.data['available_soon'][StoreLang] }}
                                  </div>
                                </b-col>
                                <b-col sm="12" md="12" lg="1">
                                </b-col>
                              </b-row>
                              <b-row v-if="d_productList.parameters[shop_id].parameters[bundle_id].data && d_productList.parameters[shop_id].parameters[bundle_id].data.data && d_productList.parameters[shop_id].parameters[bundle_id].data.data['0'] && d_productList.parameters[shop_id].parameters[bundle_id].data.data['0']['13'] && d_productList.parameters[shop_id].parameters[bundle_id].data.data['0']['13']['val']['value'] === '1'" style="margin: 3px; min-height: 50px; text-align: center; font-size: 20px;">
                                <b-col sm="12" md="12">
                                  {{ f_calculateBundleTotalPrice(d_productList.parameters[shop_id].parameters[bundle_id]) }}
                                </b-col>
                              </b-row>
                              <template v-if="d_productList.parameters[shop_id].parameters[bundle_id].param_order.length > 0">
                                  <template v-for="(product_id, product_id_index) in d_productList.parameters[shop_id].parameters[bundle_id].param_order">
                                    <b-row v-if="d_productList.parameters[shop_id].parameters[bundle_id].parameters[product_id].data.data['0'] && d_productList.parameters[shop_id].parameters[bundle_id].parameters[product_id].data.data['0']['29'] && d_productList.parameters[shop_id].parameters[bundle_id].parameters[product_id].data.data['0']['29']['val'] && d_productList.parameters[shop_id].parameters[bundle_id].parameters[product_id].data.data['0']['29']['val']['value'] === '1' && d_productList.parameters[shop_id].parameters[bundle_id].parameters[product_id].data.data['0'] && d_productList.parameters[shop_id].parameters[bundle_id].parameters[product_id].data.data['0']['9'] && d_productList.parameters[shop_id].parameters[bundle_id].parameters[product_id].data.data['0']['9']['lang'] && d_productList.parameters[shop_id].parameters[bundle_id].parameters[product_id].data.data['0']['9']['lang'][StoreLang] && d_productList.parameters[shop_id].parameters[bundle_id].parameters[product_id].data.data['0'] && d_productList.parameters[shop_id].parameters[bundle_id].parameters[product_id].data.data['0']['10'] && d_productList.parameters[shop_id].parameters[bundle_id].parameters[product_id].data.data['0']['10']['lang'] && d_productList.parameters[shop_id].parameters[bundle_id].parameters[product_id].data.data['0']['10']['lang'][StoreLang]" style="margin: 0px; border-bottom: solid 1px #eaeaea; padding: 3px; text-align: left;">
                                      <b-col sm="12" md="12">
                                          <i style="border-radius: 10px; padding-right: 5px; margin: 3px; padding-left: 5px; width: 30px; background-color: lightblue; text-align: center;">
                                            *
                                            <!-- {{ (product_id_index + 1).toString() }} -->
                                          </i>

                                        <strong style="font-size: 14px;" v-if="">
                                          {{ d_productList.parameters[shop_id].parameters[bundle_id].parameters[product_id].data.data['0']['9']['lang'][StoreLang] }}
                                        </strong>
                                        <small style="font-size: 14px;">
                                         {{ d_productList.parameters[shop_id].parameters[bundle_id].parameters[product_id].data.data['0']['10']['lang'][StoreLang] }}
                                        </small>
                                      </b-col>
                                    </b-row>
                                  </template>
                                </template>
                              <template v-if="false && d_productList.parameters[shop_id].parameters[bundle_id].data.data['0']['2'] && d_productList.parameters[shop_id].parameters[bundle_id].data.data['0']['2'].list && d_productList.parameters[shop_id].parameters[bundle_id].data.data['0']['2'].list.length > 0">
                                  <template v-for="(feature_item, feature_item_index) in d_productList.parameters[shop_id].parameters[bundle_id].data.data['0']['2']['list']">
                                    <b-row v-if="feature_item['11'] && feature_item['11'].val && feature_item['11'].val.value && ['1'].indexOf(feature_item['11'].val.value) !== -1" style="margin: 0px; border-bottom: solid 1px #eaeaea; padding: 3px; text-align: center;">
                                      <b-col sm="12" md="12">
                                        <small> {{ feature_item['3']['lang'][StoreLang] }} </small>
                                      </b-col>
                                    </b-row>
                                  </template>
                                </template>
                            </b-card>
          <!--                   <b-row style="margin: 0px; padding: 5px; height: 40px; background-color: #dbfbf5; border-radius: 0px 0px 10px 10px; text-align: center; cursor: pointer;" @click="f_goToBundleDetails(d_productList.parameters[shop_id].data.client_id, d_productList.parameters[shop_id].parameters[bundle_id].data.client_id)">
                              <b-col sm="12" md="9">
                                {{ StoreLangTranslation.data['details'][StoreLang] }}
                              </b-col>
                              <b-col sm="12" md="3">
                                 <i class="fa fa-angle-right float-right font-lg"></i>
                              </b-col>
                            </b-row>
           -->                </b-col>
                        </template>
                      </b-row>
                      <hr>
                    </b-col>
                    <b-col sm="12" lg="2"></b-col>
                </b-row>
              </template>
              <b-row style="font-size: 14px;">
                <b-col sm="12" lg="2"></b-col>
                <b-col sm="12" lg="8">
                  <template v-if="d_productList.parameters[shop_id].no_groups && d_productList.parameters[shop_id].no_groups.id_list && d_productList.parameters[shop_id].no_groups.id_list.length > 0">
                    <b-row style="margin-bottom: 20px;">
                      <b-col v-for="(no_group_id, no_group_id_index) in d_productList.parameters[shop_id].no_groups.id_list" cols="12" sm="12" lg="4">
                        <b-card footer-class="px-3 py-2" style="height: 100%;">
                          <b-row style="margin: 5px;">
                            <b-col sm="12" md="12">
                              <strong> {{ d_productList.parameters[shop_id].parameters[no_group_id].data.data['0']['1']['lang'][StoreLang] }} </strong>
                            </b-col>
                          </b-row>
                          <div slot="footer">
                            <b-link class="font-weight-bold font-xs btn-block text-muted" href="javascript:;" @click="f_goToBundleDetails(x.key)">Detaylar <i class="fa fa-angle-right float-right font-lg"></i></b-link>
                          </div>
                        </b-card>
                      </b-col>
                    </b-row>
                  </template>
                </b-col>
                <b-col sm="12" lg="2"></b-col>
              </b-row>
            </template>
          </template>
        
      </div>
    </div>
  </div>
</template>

<script>
import { ClsRoute } from '@/wam/modules/route';
import store from '@/store';
import { ServiceFunctions } from '@/wam/modules/service';
import {
  default as PageInfo
} from '@/components/widgets/PageInfo';
import { mapGetters } from 'vuex';
export default {
  name: 'shop',
  computed: mapGetters({
    StoreDevice: 'StoreDevice',
    StoreLang: 'StoreLang',
    StoreLangList: 'StoreLangList',
    StoreLangTranslation: 'StoreLangTranslation',
    StoreFrontendSettings: 'StoreFrontendSettings',
    StoreCurrencyType: 'StoreCurrencyType',
  }),
  components: {
    PageInfo,
  },
  props: {},
  data () {
    return {
      d_loading: false,
      d_currencyType: '0', // 0 tl, 1 dollar
      d_user: '',
      d_hoverIndex: '',
      d_searchText: '',
      d_frontendClientID: '',
      d_frontendList: [],
      d_productList: '',
    };
  },
  created: function () {
    // this.d_user = JSON.parse(localStorage.getItem('user'));
    this.f_bundleAndProductListActive();
  },
  beforeMount () {},
  mounted: function () {
    // console.log(this.StoreFrontendSettings);
  },
  methods: {
    f_viewBundle: function (shop_id, bundle_id) {
      // normalde default olarak bundle görünebilir şekilde çalışıyor. Ama in_store_visibility görünmesin şeklinde tanımlandıysa gösterilmiyor.
      let view_eligible = true;
      try {
        let bundle_data = this.d_productList.parameters[shop_id].parameters[bundle_id].data;
        let in_store_visibility = bundle_data['data']['0']['20']['val']['value'];
        if (in_store_visibility === '0') {
          view_eligible = false;
        }
      } catch(err) {}
      return view_eligible;
    },
    f_calculateBundleTotalPrice: function (bundle_products) {
      let currency_text = this.StoreCurrencyType.label;
      let total_amount = 0;
      for (let product_id in bundle_products.parameters) {
        try {
          for (let currency_amount_index in bundle_products.parameters[product_id].data.data['0']['25'].list) {
            let currency_amount_item = bundle_products.parameters[product_id].data.data['0']['25'].list[currency_amount_index];
            try {
              if (currency_amount_item['26']['val']['value'] === this.StoreCurrencyType.value) {
                total_amount += parseFloat(currency_amount_item['27']['val']);
              }
            } catch(err) {}
          }
        } catch(err) {}
      }
      return total_amount + ' ' + currency_text;
    },
    f_goToBundleDetails: function (user_shop_client_id, user_shop_bundle_client_id) {
      this.$router.push({ path: ClsRoute.f_getRoutePath('product_details', this.StoreLang), query: { 'user_shop_client_id': user_shop_client_id, 'user_shop_bundle_client_id': user_shop_bundle_client_id } });
    },
    f_bundleAndProductListActive: function () {
      this.d_loading = true;
      let query = '';
      let data = {};
      ServiceFunctions.bundle_and_product_list_active(query, data)
        .then(resp => {
          this.d_loading = false;
          if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
            if (resp.data.list) {
              this.d_productList = resp.data.list;
            } else {
              alert(this.StoreLangTranslation.data['process_completed'][this.StoreLang]);
            }
          } else {
            alert(resp.data.status_code + ' = ' + resp.data.status_message);
          }
        });
    },
  },
  watch: {}
};

</script>

<style type="text/css">
.product-card {
  font-size: 14px;
  height: 100%;
  min-height: 300px;
  color: black;
  border-radius: 20px 20px 20px 20px;
  margin-bottom: 0px;
  border: none;
  box-shadow: 0px 0px 1px 1px #bcb1b1;
}  

.product-card:hover {
  font-size: 14px;
  height: 100%;
  min-height: 300px;
  color: black;
  border-radius: 20px 20px 20px 20px;
  margin-bottom: 0px;
  margin: -1px;
  border: none;
  box-shadow: -1px 1px 3px 3px #bcb1b1;
  cursor: pointer;
}  

.product-card-old {
  height: 100%;
  min-height: 300px;
  color: black;
  background-image: linear-gradient(90deg, rgb(0 250 255), rgb(84 229 238), rgb(55 199 217));
  border-radius: 20px 20px 20px 20px;
  box-shadow: -1px -1px rgba(252, 252, 252, 1.0);
  margin-bottom: 0px;
  border: none;
  box-shadow: 0px 0px 3px 3px #bcb1b1;
}  

.product-card-old:hover {
  height: 100%;
  min-height: 300px;
  color: black;
  background-image: linear-gradient(120deg, rgb(0 250 255), rgb(84 229 238), rgb(55 199 217));
  border-radius: 20px 20px 20px 20px;
  box-shadow: -1px -1px rgba(252, 252, 252, 1.0);
  margin-bottom: 0px;
  margin: -3px;
  border: none;
  box-shadow: 0px 0px 10px 10px #bcb1b1;
}  
</style>

